import { View, Text } from "@react-pdf/renderer";
import { ConditionalFragment } from "react-conditionalfragment";
import { AnalyserReportSection } from "../../../dashboard/analyser/Analyser";
import { SectionPage } from "./SectionPage";
import { SectionsPageStyles } from "./sectionsPageStyles";

//* Common reporting page for analyser reports - Performance Page
interface SectionsPageProps {
    companyColor: string,
    learners: Array<{ id: string, firstName: string, lastName: string }>,
    reportSections: AnalyserReportSection[],
    currencySymbol: string,
}

export const SectionsPage = (props: SectionsPageProps) => {
    const {
        companyColor, learners, reportSections, currencySymbol,
    } = props;

    return (
        <View>
            {
                reportSections.map((reportSection, sectionIndex) => {
                    return (
                        <View key={reportSection.id}>
                            <ConditionalFragment showIf={!!reportSection.questionnaireName}>
                                <View>
                                    <Text style={[SectionsPageStyles.questinnaireHeading, { color: companyColor }]}>
                                        {reportSection.questionnaireName}
                                    </Text>
                                </View>
                            </ConditionalFragment>
                            <SectionPage
                                companyColor={companyColor}
                                learners={learners}
                                reportSection={reportSection}
                                currencySymbol={currencySymbol}
                            />
                        </View>
                    )
                })
            }
        </View>
    );
};