import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const SectionPageStyles = StyleSheet.create({
    subHeadingContainer: {
        fontSize: 14,
        paddingTop: "4mm",
        paddingBottom: "4mm",
        flexDirection: "row",
    },
    subHeadingBold: {
        fontWeight: "bold",
    },
    subHeading: {
        fontWeight: "normal",
    },
    learnerResponsesContainer: {
        marginLeft: "3mm",
        flexDirection: "column",
        marginBottom: "3mm",
    },
    learnerName: {
        marginTop: "3mm",
        marginBottom: "5mm",
        fontSize: 14,
        fontWeight: "bold",
    },
    responseContainer: {
        flexDirection: "row",
        marginBottom: "2mm",
    },
    responseText: {
        fontSize: 14,
        fontWeight: "normal",
    },
    responseTextBold: {
        fontWeight: "bold",
        paddingRight: "3mm",
    }
});

