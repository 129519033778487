import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const AnalyserReportHeaderStyles = StyleSheet.create({
    header: {
        //borderBottom: "1px solid black",
        height: "25mm",
    },
    headerContainer: {
        borderBottom: "1px solid black",
        paddingBottom: "5mm",
        flexDirection: "row",
    },
    impactsImageHeaderContainer: {
        textAlign: "right",
    },
    impactsImageHeader: {
        width: "auto",
        height: "100%",
    },
    headerTextContainer: {
        paddingTop: "3mm",
        width: "150mm",
    },
    headerText: {
        color: "black",
        fontSize: 12,
    },
    headerPagePadding: {
        marginTop: "5mm",
        height: "5mm",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
    }
});

