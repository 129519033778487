import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Button, ButtonGroup, Col, Collapse, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { GetToggleStateArrayValue, useToggleState, useToggleStateArray } from "use-toggle-state";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { TagLikeItem } from "../../../shared/tagSelector/TagSelector";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import "./Analyser.scss";
import { AnalyserInterrogationResult, InterrogateAnalyserData } from "./AnalyserDataInterrogation";
import { AnalyserDragComponent } from "./AnalyserDragComponent";
import { getIconCssSuffixFromCategoryName } from "./GetIconFromCategoryName";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { useAnalyserInterrogationSupportingData } from "../../../api/main/analyser/viewModels/useAnalyserInterrogationSupportingData";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { ChartDataSeries } from "../chartDataUtilities/ChartData";
import { chartDataConverters } from "../chartDataUtilities/ChartDataConverter";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { AnalyserCheckedListModal } from "./AnalyserCheckedListModal";
import { AnalyserReportPrintModal } from "../../reporting/AnalyserReportPrintModal";
import { PdfPrintButtons } from "./PdfPrintButtons";
import { useProfiles } from "../../../api/main/profiles/useProfiles";
import moment, { Moment } from "moment";
import { useSubscriptionAnalyserViewModel } from "../../../api/main/dashboard/viewModels/useSubscriptionAnalyserViewModel";
import { QuestionnaireSection } from "../../../api/main/models/QuestionnaireSection";
import { AnalysisType } from "../../../api/main/models/AnalysisType";
import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { isResponseTypeForMeasure, QuestionResponseType, questionResponseTypeDisplayName } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { AnalyserSelectGraphTypeModal } from "./AnalyserSelectGraphTypeModal";
import { useCurrentUserRoles } from "../../../api/account";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { useCurrentUserProfile } from "../../../api/main/profiles/useCurrentUserProfile";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";


export const Analyser = () => {
    // Work out the subscription to show for.
    const { subscriptionId: subscriptionIdParam } = useParams<{ subscriptionId: string | undefined }>();
    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const subscriptionId = subscriptionIdParam ?? mySubscriptionId;
    //Checking whether user is a department admin so we can filter out stuff not in their department
    const userRoles = useCurrentUserRoles();
    const isDepartmentAdmin = userRoles?.data?.roles?.includes(IdentityRoles.SubscriptionDepartmentAdministration) && !userRoles?.data?.roles?.includes(IdentityRoles.Administration || IdentityRoles.SubscriptionAdministration);
    const userProfile = useCurrentUserProfile();
    const userDepartmentId = (isDepartmentAdmin && !!userProfile.data.model?.subscriptionDepartmentId) ? userProfile.data.model.subscriptionDepartmentId : null;
    const { t } = useTranslation();

    // Load the data available.
    //
    const {
        data: {
            model: subscription,
            subscriptionDepartments,
            questionnaires,
            questionnaireRespondentSessions,
            learningUnits,
            questionnaireAnalysisTypeLinks,
            analysisTypes,
        },
        isLoading,
        errors: loadErrors
    } = useSubscriptionAnalyserViewModel(subscriptionId, { subscriptionDepartmentId: userDepartmentId, includeOngoing: false });

    const responseUserIds = useMemo(() => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        let requiredSessions = questionnaireRespondentSessions;

        // if restricted to a department then filter out any users not in that department
        if (isDepartmentAdmin) {
            requiredSessions = requiredSessions.filter(item => item.subscriptionDepartmentId === userDepartmentId);
        }

        // get all the userids from the responses
        return requiredSessions.map(r => r.userId);
    }, [questionnaireRespondentSessions, isDepartmentAdmin, userDepartmentId]);


    // Load profiles separately so we don't have to wait for them to begin making selections    
    const {
        data: {
            items: profiles,
        },
        errors: profilesErrors, 
    } = useProfiles({ userIds: responseUserIds, lazy: false });

    // NOTE We only load questions once the form has been submitted for the first time.
    const [requiredQuestionnaireIds, setRequiredQuestionnaireIds] = useState<Array<string>>([]);
    //Get questions and question responses for questionnaires
    const {
        data: {
            questions,
            questionResponses,
        }, isLoading: isQuestionsLoading, errors: questionsErrors
    } = useAnalyserInterrogationSupportingData(requiredQuestionnaireIds);

    // Get impacts from the QuestionType enum
    const allQuestionTypes = Object.values(QuestionType);
    const questionTypeTags = useMemo(() => {
        const ret = new Array<TagLikeItem>();


        allQuestionTypes.forEach((type: string) => {
            ret.push({ id: type, name: type });
        });

        return ret;

    }, [allQuestionTypes]);

    // Get Measures from the QuestionResponseType enum
    const allQuestionResponseTypes = Object.values(QuestionResponseType);
    const measureQuestionResponseTypes = useMemo(() => {
        const ret: Array<QuestionResponseType> = [];
        allQuestionResponseTypes.forEach((type: QuestionResponseType) => {
            if (isResponseTypeForMeasure(type)) {
                ret.push(type);
            }
        });

        return ret;

    }, [allQuestionResponseTypes]);
    const measureQuestionResponseTags = useMemo(() => {
        const ret = new Array<TagLikeItem>();

        // Explicitly remove Yes/No from the list of measure types
        measureQuestionResponseTypes.filter(item => item !== QuestionResponseType.YesNo).forEach((type: QuestionResponseType) => {
            ret.push({ id: questionResponseTypeDisplayName(type, t), name: questionResponseTypeDisplayName(type, t) });
        });

        return ret;

    }, [measureQuestionResponseTypes, t]);

    //Combine profiles and departments into a single array of TagLikeItems
    const learnersDepartmentsTags = useMemo(() => {
        if (!subscriptionDepartments || !profiles) {
            return [];
        }

        let merge = new Array<TagLikeItem>();
        const learnerTags = new Array<TagLikeItem>();
        subscriptionDepartments?.forEach(department => {
            if (isDepartmentAdmin && department.id === userDepartmentId) {
                merge.push({ id: department.id, name: department.name });
            }
            else if (!isDepartmentAdmin) {
                merge.push({ id: department.id, name: department.name });
            }
        });
        subscriptionDepartments?.forEach(department => {
            const learners = profiles?.filter((l: any) => l.subscriptionDepartmentId === department.id);
            learners?.forEach((profile: any) => {
                if (!!profile.firstName || !!profile.lastName) {
                    learnerTags.push({ id: profile.userId, name: profile.firstName + " " + profile.lastName });
                }
            });
        });
        const profilesWithoutDepartment = profiles?.filter((l: any) => l.subscriptionDepartmentId === null);
        profilesWithoutDepartment?.forEach((profile: any) => {
            if (!!profile.firstName || !!profile.lastName) {
                learnerTags.push({ id: profile.userId, name: profile.firstName + " " + profile.lastName });
            }
        });
        learnerTags.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0));
        merge = merge.concat(learnerTags);
        return merge;
    }, [subscriptionDepartments, profiles, isDepartmentAdmin, userDepartmentId]);

    const analysisTypeTags = useMemo(() => {
        if (!analysisTypes) {
            return [];
        }

        const ret: Array<TagLikeItem> = [];
        analysisTypes?.forEach(analysisType => {
            ret.push({ id: analysisType.id, name: analysisType.name });
        });
        return ret;
    }, [analysisTypes]);


    const questionnaireSections = useMemo(() => {
        if (!questionnaires) {
            return [];
        }
        return questionnaires?.flatMap(item => item.questionnaireSections as QuestionnaireSection[]);
    }, [questionnaires]);

    // Get a list of unique section name tags.
    const uniqueQuestionnaireSectionNameTags = useMemo(() => {
        if (!questionnaireSections) {
            return [];
        }

        const ret: Array<TagLikeItem> = [];
        questionnaireSections?.forEach(section => {
            const name = section["name"];
            if (!ret.find(n => n.name === name)) {
                ret.push({ id: section.name, name: section.name });
            }
        });

        // Sort the sections into name order
        return ret.sort((a, b) => {
            const aNameLower = a.name.toLowerCase();
            const bNameLower = b.name.toLowerCase();
            if (aNameLower === bNameLower) {
                return 0;
            } else if (aNameLower > bNameLower) {
                return 1;
            } else {
                return -1;
            }
        });
    }, [questionnaireSections]);

    // Get the correct field into the tag selector for LearningUnits
    const uniqueLearningUnitsByField = useCallback((field: "provider" | "name" | "learningUnitType") => {
        if (!learningUnits) {
            return [];
        }
        let ret: Array<TagLikeItem> = [];
        learningUnits?.filter(lu => questionnaires.find(q => q.id === lu.questionnaireId))?.forEach(unit => {
            const fieldToCheck = unit[field];
            if (!ret.find(lu => lu.name === fieldToCheck)) {
                ret.push({ id: fieldToCheck, name: fieldToCheck });
            }
        });

        // Sort the sections into name order
        ret = ret.sort((a, b) => {
            const aNameLower = a.name.toLocaleLowerCase();
            const bNameLower = b.name.toLocaleLowerCase();
            if (aNameLower === bNameLower) {
                return 0;
            } else if (aNameLower > bNameLower) {
                return 1;
            } else {
                return -1;
            }
        });

        // Filter out any blanks
        return ret.filter(item => !!item.name);
    }, [learningUnits, questionnaires]);

    // Left segment------------------------------------------------------------
    // Select Resources
    const [fromDate, setFromDate] = useState<Moment | null>(null);
    const [toDate, setToDate] = useState<Moment | null>(null);
    const [allTime, setAllTime] = useState<boolean>(true);

    const [providers, setProviders] = useToggleStateArray<string | null>();
    const [allProviders, setAllProviders] = useState<boolean>(true);
    // Make sure allProviders gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(providers, uniqueLearningUnitsByField('provider'))) { setAllProviders(true); } }, [providers, uniqueLearningUnitsByField, setAllProviders]);

    const [courseNames, setCourseName] = useToggleStateArray<string | null>();
    const [allCourseNames, setAllCourseNames] = useState<boolean>(true);
    // Make sure allCourseNames gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(courseNames, uniqueLearningUnitsByField('name'))) { setAllCourseNames(true); } }, [courseNames, uniqueLearningUnitsByField, setAllCourseNames]);

    const [courseTypes, setCourseTypes] = useToggleStateArray<string | null>();
    const [allCourseTypes, setAllCourseTypes] = useState<boolean>(true);
    // Make sure allCourseTypes gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(courseTypes, uniqueLearningUnitsByField('learningUnitType'))) { setAllCourseTypes(true); } }, [courseTypes, uniqueLearningUnitsByField, setAllCourseTypes]);

    const [learnersDepartments, setLearnersDepartments] = useToggleStateArray<string | null>();
    const [allLearnersDepartments, setAllLearnersDepartments] = useState<boolean>(true);
    // Make sure allLearnersDepartments gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(learnersDepartments, learnersDepartmentsTags)) { setAllLearnersDepartments(true); } }, [learnersDepartments, learnersDepartmentsTags, setAllLearnersDepartments]);

    //Select IMPACTS
    const [sectionNames, setSectionNames] = useToggleStateArray<string | null>();
    const [allSectionNames, setAllSectionNames] = useState<boolean>(true);
    // Make sure allSectionNames gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(sectionNames, uniqueQuestionnaireSectionNameTags)) { setAllSectionNames(true); } }, [sectionNames, uniqueQuestionnaireSectionNameTags, setAllSectionNames]);
    // Stateful array of section names for drag/drop use
    const [selectedSectionNames, setSelectedSectionNames] = useState<string[]>([] as string[]);
    const excludeSection = (sectionName: string, name: string) => {
        setSectionNames(sectionName);
        setSelectedSectionNames(selectedSectionNames.filter(cat => cat !== name));
        setAllSectionNames(false);
    }

    const [impacts, setImpacts] = useToggleStateArray<string | null>();
    const [allImpacts, setAllImpacts] = useState<boolean>(true);
    // Make sure allImpacts gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(impacts, questionTypeTags)) { setAllImpacts(true); } }, [impacts, questionTypeTags, setAllImpacts]);

    const [categories, setCategories] = useToggleStateArray<string | null>();
    const [allCategories, setAllCategories] = useState<boolean>(true);
    // Make sure allCategories gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(categories, analysisTypeTags)) { setAllCategories(true); } }, [categories, analysisTypeTags, setAllCategories]);
    // Stateful array of categories for drag/drop use
    const [selectedCategories, setSelectedCategories] = useState<string[]>([] as string[]);
    const excludeCategory = (categoryId: string, categoryName: string) => { //These arguments are redundant, but I need it to match the category equivalent for prop definition purposes
        setCategories(categoryId);
        setSelectedCategories(selectedCategories.filter(cat => cat !== categoryId));
        setAllCategories(false);
    }

    const [measures, setMeasures] = useToggleStateArray<string | null>();
    const [allMeasures, setAllMeasures] = useState<boolean>(true);
    // Make sure allMeasures gets set if all are selected individually
    useEffect(() => { if (checkIfAllSelected(measures, measureQuestionResponseTags)) { setAllMeasures(true); } }, [measures, measureQuestionResponseTags, setAllMeasures]);

    //Graph selection 
    const [categoryGraphMode, setCategoryGraphMode] = useState<boolean>(false);
    const [graphCategories, setGraphCategories] = useToggleStateArray<string | null>();
    const [categoryGraphFormat, setCategoryGraphFormat] = useState<'bar' | 'horizontalBar'>('bar');
    const [graphModalOpen, toggleGraphModalOpen] = useState<boolean>(false);
    const [includeGraph, setIncludeGraph] = useState<boolean>(false);

    const selectedDepartments = useMemo(() => {
        if (!subscriptionDepartments) {
            return [];
        }

        return subscriptionDepartments?.filter(it => learnersDepartments(it.id))
    }, [subscriptionDepartments, learnersDepartments]);
    const selectedUsers = useMemo(() => {

        if (!profiles) {
            return [];
        }

        const ret = profiles?.filter((it: any) => learnersDepartments(it.userId));

        if (!!ret.length) {
            ret.sort((a: any, b: any) => {
                if (a.firstName === b.firstName) {
                    if (a.lastName === b.lastName) {
                        return 0;
                    } else if (a.lastName > b.lastName) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else if (a.firstName > b.firstName) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }

        return ret;
    }, [profiles, learnersDepartments]);

    // Filter the questionnaires
    const questionnairesFiltered = useMemo(() => {
        return questionnaires?.filter(q => {

            if (allTime && allProviders && allCourseNames && allCourseTypes && allLearnersDepartments && allSectionNames) {
                return true;
            }

            const usersInThoseDepartments = profiles?.filter((it: any) => selectedDepartments.find(d => it.subscriptionDepartmentId === d.id));
            // Add to the users selected directly all the department users that aren't there already
            usersInThoseDepartments?.forEach((profile: any) => {
                if (!selectedUsers?.find((it: any) => it.userId === profile.userId)) {
                    selectedUsers?.push(profile);
                }
            });
            // Date filtering
            const dateFilter = (allTime || (moment(q.startDate) >= fromDate! && moment(q.endDate) <= toDate!));

            // Provider filtering
            const providerFilter = allProviders || providers(learningUnits?.find(l => l.questionnaireId === q.id)?.provider ?? null);

            // Course Name filtering
            const courseNameFilter = allCourseNames || courseNames(learningUnits?.find(l => l.questionnaireId === q.id)?.name ?? null);

            // Course Type filtering
            const courseTypeFilter = allCourseTypes || courseTypes(learningUnits?.find(l => l.questionnaireId === q.id)?.learningUnitType ?? null);

            // Learner/Department Filtering
            const learnerDepartmentFilter = allLearnersDepartments || !!questionnaireRespondentSessions?.find(r => (selectedUsers?.find(u => u.userId === r.userId)) && r.questionnaireId === q.id);

            // Section Name filtering
            const sectionNameFilter = allSectionNames || !!questionnaireSections?.find(s => sectionNames(s.name) && s.questionnaireId === q.id);

            // Impact, category (analysisType), and measure filtering will take place later once the questions for the questionnaires filtered thus far
            // are passed on. It works neater in my head if questions are loaded seperately in the other function based on filtered questionnaires from here.
            return dateFilter && providerFilter && courseNameFilter && courseTypeFilter && learnerDepartmentFilter && sectionNameFilter;
        });
    }, [allTime, allProviders, allCourseNames, allCourseTypes, allLearnersDepartments, allSectionNames, providers, courseNames, courseTypes,
        sectionNames, fromDate, toDate, learningUnits, questionnaireRespondentSessions, questionnaireSections, questionnaires, profiles,
        selectedDepartments, selectedUsers]);


    // Set defaults on load
    const [defaultsLoaded, setDefaultsLoaded] = useState<boolean>(false);
    useEffect(() => {
        if (!!defaultsLoaded) {
            // do this only once
            return;
        }

        if (!uniqueQuestionnaireSectionNameTags.length || !analysisTypeTags.length || !questionnairesFiltered.length || !learnersDepartmentsTags.length) {
            // Data not loaded yet
            return;
        }

        //Trainer/Provider
        uniqueLearningUnitsByField('provider').forEach(tag => setProviders(tag.id));

        // Course Name
        uniqueLearningUnitsByField('name').forEach(tag => setCourseName(tag.id));

        // Course Type
        uniqueLearningUnitsByField('learningUnitType').forEach(tag => setCourseTypes(tag.id));

        // Learner/Department
        learnersDepartmentsTags.forEach(tag => setLearnersDepartments(tag.id));

        // Section Names
        uniqueQuestionnaireSectionNameTags.forEach(tag => setSectionNames(tag.id));
        setSelectedSectionNames(uniqueQuestionnaireSectionNameTags.map(tag => tag.name));

        // Categories (Analysis Types)
        analysisTypeTags.forEach(tag => setCategories(tag.id));
        setSelectedCategories(analysisTypeTags.map(tag => tag.id));

        // Impacts
        allQuestionTypes.forEach(tag => setImpacts(tag));

        // Measures
        measureQuestionResponseTags.forEach(tag => setMeasures(tag.id));

        // Questionaires
        setRequiredQuestionnaireIds(questionnairesFiltered?.map(item => item.id))

        // Make sure we don't do this again
        setDefaultsLoaded(true);

    }, [uniqueQuestionnaireSectionNameTags, setSectionNames, setDefaultsLoaded, defaultsLoaded, setSelectedSectionNames,
        analysisTypeTags, setCategories, uniqueLearningUnitsByField, learnersDepartmentsTags, setProviders, setCourseName, setCourseTypes,
        setLearnersDepartments, allQuestionTypes, setImpacts, measureQuestionResponseTags, setMeasures, setRequiredQuestionnaireIds, questionnairesFiltered]);

    const analysisResults = useMemo(() => {
        if (!subscriptionDepartments || !profiles || !questions || !questionResponses || !questionnaireRespondentSessions || !analysisTypes) {
            return;
        }

        // breaking down learner/department selection into just a list of people
        const selectedDepartments = subscriptionDepartments?.filter(it => learnersDepartments(it.id));
        const usersInThoseDepartments = profiles?.filter((it: any) => selectedDepartments.find(d => it.subscriptionDepartmentId === d.id));
        const selectedUsers = profiles?.filter((it: any) => learnersDepartments(it.userId));
        // Add to the users selected directly all the department users that aren't there already
        usersInThoseDepartments?.forEach((profile: any) => {
            if (!selectedUsers?.find((it: any) => it.userId === profile.userId)) {
                selectedUsers?.push(profile);
            }
        });
        const questionsFiltered = questions?.filter(q => questionnairesFiltered.find(f => q.questionnaireId === f.id));

        const result = InterrogateAnalyserData({
            questionnaires: questionnairesFiltered,
            questions: questionsFiltered,
            questionResponses: questionResponses,
            learners: allLearnersDepartments ? profiles : selectedUsers,
            questionnaireRespondantSessions: questionnaireRespondentSessions?.filter(qr => (allLearnersDepartments || selectedUsers?.find(u => qr.userId === u.userId)) && questionnairesFiltered?.find(qf => qr.questionnaireId === qf.id)),
            analysisTypes: analysisTypes?.filter(a => allCategories || categories(a.id)),
            allAnalysisTypes: allCategories,
            questionnaireAnalysisTypeLinks: questionnaireAnalysisTypeLinks,
            impacts: allQuestionTypes.filter(i => allImpacts || impacts(i)),
            measures: measureQuestionResponseTypes.filter(m => allMeasures || measures(m)),
            sections: questionnaireSections?.filter(s => allSectionNames || (questionnaireSections?.filter(s => sectionNames(s.name) && questionnairesFiltered?.find(q => s.questionnaireId === q.id)))),
            learningUnits: learningUnits
        })

        return result;
    }, [learnersDepartments, sectionNames, learningUnits, profiles, questionnaireRespondentSessions, questionnaireSections,
        subscriptionDepartments, analysisTypes, categories, impacts, measures, allCategories, questionnaireAnalysisTypeLinks,
        questionnairesFiltered, allLearnersDepartments, allImpacts, allMeasures, questions, questionResponses, allSectionNames, allQuestionTypes, measureQuestionResponseTypes]);

    const noResultSections = useMemo(() => {
        if (allSectionNames) {
            return [];
        }

        return selectedSectionNames?.filter(section => !analysisResults?.sectionResults?.find(it => it.sectionName === section));
    }, [selectedSectionNames, analysisResults?.sectionResults, allSectionNames]);


    const noResultCategories = useMemo(() => {
        if (allCategories) {
            return [];
        }

        return selectedCategories?.filter(category => !analysisResults?.categoryResults?.find(it => it.analysisType.id === category));
    }, [selectedCategories, analysisResults?.categoryResults, allCategories]);


    // Show results by
    const [showBy, setShowBy] = useToggleStateArray<string | null>(['category', 'section', 'question']);
    // Special case for showBy question (if it's selected on its own, also select section)
    const toggleShowBy = (option: string) => {
        setShowBy(option);

    };

    // Right segment-----------------------------------------------------------
    const [showRightSegment, setShowRightSegment] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(true);

    // Drag and drop support for categories
    const reorderCategories = (e: DragEndEvent) => {
        if (!e.over) return;

        const activeCategory = selectedCategories.find(cat => cat === e.active.id);
        const overCategory = selectedCategories.find(cat => cat === e.over?.id);

        if (e.active.id !== e.over.id) {
            setSelectedCategories((selectedCategories) => {
                const oldIdx = selectedCategories.indexOf(activeCategory!);
                const newIdx = selectedCategories.indexOf(overCategory!);
                return arrayMove(selectedCategories, oldIdx, newIdx);
            });
        }
    };
    // Drag and drop support for sectionNames
    const reorderSectionNames = (e: DragEndEvent) => {
        if (!e.over) return;

        if (e.active.id !== e.over.id) {
            setSelectedSectionNames((selectedSectionNames) => {
                const oldIdx = selectedSectionNames.indexOf(e.active.id.toString());
                const newIdx = selectedSectionNames.indexOf(e.over!.id.toString());
                return arrayMove(selectedSectionNames, oldIdx, newIdx);
            });
        }
    };

    const setDates = useCallback(() => {
        if (allTime) { //If this is being triggered while all is true, it means that it's about to be false
            const lastMonthBegin = moment().subtract(1, 'months').startOf('month');
            const lastMonthEnd = moment().subtract(1, 'months').endOf('month');
            if (fromDate === null) {
                setFromDate(lastMonthBegin);
            }
            if (toDate === null) {
                setToDate(lastMonthEnd);
            }
        }
    }, [allTime, fromDate, toDate, setFromDate, setToDate]);

    //Category results pie chart handling
    const categoryChartData = useCategoryChartData(!!analysisResults && analysisResults.categoryResults ? analysisResults?.categoryResults.filter(c => graphCategories(c.analysisType.id)) : []);
    const [showCatGraph, setShowCatGraph] = useState<boolean>(false);
    //Learner results pie chart handling
    const resultsChartData = useCompletionChartData(!!analysisResults && analysisResults?.resultsPieChartResults?.incompletedProfiles !== undefined ? analysisResults?.resultsPieChartResults?.incompletedProfiles?.length : 0, !!analysisResults && analysisResults?.resultsPieChartResults?.completedProfiles !== undefined ? analysisResults?.resultsPieChartResults?.completedProfiles?.length : 0);
    // Convert data into format needed for our pie chart
    const { options: seriesOptions } = useMemo(() => chartDataConverters.toPie(resultsChartData), [resultsChartData]);
    // Category chart
    const { series: catSeries, options: catSeriesOptions } = chartDataConverters.toColumn(categoryChartData);

    // Compile all the options we want to use.
    const options = useMemo(() => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: true,
                formatter: (val, options) => {
                    // Show value instead of percentage
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
            },

            legend: {
                position: 'bottom',
            },

            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#ffffff',
                            },
                            name: {
                                show: true,
                                color: '#ffffff'
                            },
                            value: {
                                show: true,
                                color: '#ffffff'
                            }
                        }
                    }
                },
                bar: {

                }
            },

            colors: [
                '#0055aa',
                '#1188FF',
            ],

            ...seriesOptions,
        } as ApexOptions;

    }, [seriesOptions]);

    const catOptions = useMemo(() => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                brush: {
                    autoScaleYaxis: true,
                },
                events: {

                },
                zoom: {
                    enabled: false,
                },

            },

            dataLabels: {
                enabled: false,
                formatter: (val, options) => {
                    // Show value instead of percentage
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
                offsetY: -20,
                textAnchor: 'middle',
            },

            legend: {
                position: 'bottom',

            },

            plotOptions: {
                bar: {
                    horizontal: categoryGraphFormat === 'bar' ? false : true,
                    colors: {
                    },
                },

            },

            colors: [
                '#1188FF',
                '#0055aa',
            ],


            yaxis: {
                decimalsInFloat: 0,
                forceNiceScale: true,
                title: {
                    text: categoryGraphFormat === 'bar' ? 'Percentage Change (%)' : '',

                }
            },

            xaxis: {
                decimalsInFloat: 0,
                forceNiceScale: true,
                title: {
                    text: categoryGraphFormat !== 'bar' ? 'Percentage Change (%)' : '',
                }
            },

            ...catSeriesOptions,
        } as ApexOptions;

    }, [catSeriesOptions, categoryGraphFormat]);

    //const resultsChartKey = useMemo(() => JSON.stringify(options), [options]);
    const catChartKey = useMemo(() => JSON.stringify(catOptions), [catOptions])

    const loading = isLoading || isQuestionsLoading;

    const [reportNameModalIsOpen, toggleReportNameModal] = useToggleState();

    const pdfPrintButtonOnClick = useCallback(() => {
        if (!analysisResults) {
            return;
        }

        if (!analysisResults.sectionResults || analysisResults.sectionResults.length === 0) {
            return;
        }
        toggleReportNameModal();
    }, [toggleReportNameModal, analysisResults]);

    const selectedLearners = useMemo(() => {
        const ret: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }> = [];

        if (loading) {
            return ret;
        }

        selectedUsers.forEach((learner) => {
            const userDepartmentId = learner.subscriptionDepartmentId;
            const department = subscriptionDepartments?.find(d => d.id === userDepartmentId);

            // get all sessions for this user that have a line manager on
            const allSessions =
                questionnaireRespondentSessions?.filter(qr => !!qr.lineManagerEmail && selectedUsers
                    .find(u => u.userId === learner.userId))
                    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

            // if we have any sessions get the latest one
            const latestSession = !!allSessions ? allSessions[0] : undefined;


            if (!department || !learnersDepartments(department?.id)) {
                ret.push({
                    id: learner.userId,
                    firstName: learner.firstName,
                    lastName: learner.lastName,
                    email: learner.user.email,
                    lineManagerName: !!latestSession ? latestSession.lineManagerName : '',
                    lineManagerEmail: !!latestSession ? latestSession.lineManagerEmail : ''
                });
            }
        });

        return ret;


    }, [learnersDepartments, selectedUsers, subscriptionDepartments, questionnaireRespondentSessions, loading]);

    const reportSections: Array<AnalyserReportSection> = useMemo(() => {
        const ret: Array<AnalyserReportSection> = [];
        if (!analysisResults) {
            return ret;
        }

        for (const sectionResult of analysisResults.sectionResults) {
            if (!sectionResult.sectionQuestionData.length || !sectionResult.sectionQuestionData[0].question) {
                continue;
            }

            ret.push({
                ...sectionResult,
            })
        }

        return ret;

    }, [analysisResults])

    const analyserNode = useMemo(() => {
        if (!analysisResults || !reportNameModalIsOpen) {
            return null;
        }

        // Put the analysis result categories into the same order as the selected categories
        analysisResults.categoryResults.sort((a, b) => selectedCategories.indexOf(a.analysisType.id) - selectedCategories.indexOf(b.analysisType.id));

        // Put the analysis result sections into the same order as the selected sections
        analysisResults.sectionResults.sort((a, b) => selectedSectionNames.indexOf(a.sectionName) - selectedSectionNames.indexOf(b.sectionName));

        // Clear the results if not needed
        if (!showResults) {
            analysisResults.learnerResults.costPerLearner = 0;
            analysisResults.learnerResults.totalCost = 0;
        }

        // Text information for criteria selected to be shown on the details page
        const courseNames: Array<string> = allCourseNames ? ['All'] : [];

        const courseTypes: Array<string> = allCourseTypes ? ['All'] : [];

        const providerNames: Array<string> = allProviders ? ['All'] : [];

        const sectionNames: Array<string> = allSectionNames ? ['All'] : selectedSectionNames;

        const categoryNames: Array<string> = allCategories ? ['All'] : selectedCategories;

        const departmentNames: Array<string> = allLearnersDepartments ? ['All'] : selectedDepartments?.map(d => d.name) ?? [];

        // Get the learning unit related names for the details page
        questionnairesFiltered?.forEach(q => {
            const qLearningUnits = learningUnits?.filter(l => l.questionnaireId === q.id);
            if (!!qLearningUnits.length) {
                qLearningUnits.forEach(ql => {
                    if (!allProviders && !!ql.provider && !providerNames.includes(ql.provider)) {
                        providerNames.push(ql.provider);
                    }
                    if (!allCourseNames && !!ql.name && !courseNames.includes(ql.name)) {
                        courseNames.push(ql.name);
                    }
                    if (!allCourseTypes && !!ql.learningUnitType && !courseTypes.includes(ql.learningUnitType)) {
                        courseTypes.push(ql.learningUnitType);
                    }
                });
            }
        });

        return (
            <AnalyserReportPrintModal
                isOpen={reportNameModalIsOpen}
                toggle={toggleReportNameModal}
                caller={'Analyser'}
                currencySymbol={subscription?.currencySymbol ?? '£'}
                analysisResults={analysisResults}
                providerNames={providerNames}
                courseNames={courseNames}
                courseTypes={courseTypes}
                sectionNames={sectionNames}
                categoryNames={categoryNames}
                departmentNames={departmentNames}
                learners={selectedLearners}
                isAllLearners={allLearnersDepartments}
                includeGraph={includeGraph}
                reportSections={reportSections}
            />
        )
    }, [subscription, reportNameModalIsOpen, toggleReportNameModal, analysisResults, learningUnits,
        questionnairesFiltered, allCourseNames, allCourseTypes, allProviders, allSectionNames,
        selectedSectionNames, allCategories, selectedCategories, selectedDepartments, allLearnersDepartments,
        includeGraph, showResults, reportSections, selectedLearners]);


    //Handles selecting users within a department when it is selected
    const departmentSelectClick = useCallback((departmentId: string, selecting: boolean) => {
        let usersInDepartment = profiles?.filter((it: any) => it.subscriptionDepartmentId === departmentId);
        usersInDepartment.forEach((user) => {
            if ((!learnersDepartments(user.userId) && selecting) || (learnersDepartments(user.userId) && !selecting)) {
                setLearnersDepartments(user.userId);
            }
        });
    }, [learnersDepartments, profiles, setLearnersDepartments]);

    const getParentDepartment = useCallback((userId: string) => {
        if (!profiles) {
            return;
        }

        const profile = profiles?.find(p => p.userId === userId);
        if (!profile) {
            return;
        }

        return profile.subscriptionDepartmentId ?? undefined;
    }, [profiles]);

    // Callback for when we submit the form that causes us to load any remaining data we require.
    const interrogateData = useCallback(() => {
        setRequiredQuestionnaireIds(questionnairesFiltered?.map(item => item.id));
    }, [setRequiredQuestionnaireIds, questionnairesFiltered,]);

    const getRespondants = useCallback((sectionName: string) => {
        const resultItem = analysisResults?.sectionResults?.find(it => it.sectionName === sectionName);
        const responses = resultItem?.sectionQuestionData.flatMap(d => d.responses).filter(d => learnersDepartments(d.userId)) ?? [];
        const uniqueRespondantIds = [...new Set(responses.map(r => r.userId))];
        const respondants = profiles?.filter(p => uniqueRespondantIds.find(id => id === p.userId));
        if (respondants === undefined) {
            return [];
        }
        return respondants;
    }, [analysisResults, learnersDepartments, profiles]);

    //Render the UI
    //
    return (
        <Background>
            <Banner fluid className="analyser-header">
                <Row>
                    <Col>
                        <h1 className="analyser-heading">
                            {
                                t('analyser.heading', 'Analyser')
                            }
                        </h1>
                        <ConditionalFragment showIf={loading}>
                            <LoadingIndicator size="lg" />
                        </ConditionalFragment>
                    </Col>

                </Row>
            </Banner>
            <Row className="main-analyser-container">
                <AlertOnErrors errors={[loadErrors, profilesErrors, questionsErrors]} />
                <Col className={"d-none d-xl-block"} lg={2}></Col>
                <Col xs={12} sm={6} xl={4} className="analyser-segment">
                    <div className="analyser-subSegment">
                        <h4 className="analyser-heading">
                            {t('analyser.leftSegment.heading.resources', 'Select Resources')}
                        </h4>
                        {/*Date From/To*/}
                        <FormGroup >
                            <Row>
                                <Col>
                                    <h6>{t('analyser.leftSegment.label.fromDate', 'From')}</h6>
                                    <Input className="analyser-dateInput" type="date" name="fromDate" id="fromDate" value={allTime || !fromDate ? '' : moment(fromDate).local().format('YYYY-MM-DD')} onChange={(e) => setFromDate(moment(e.target.value))} />
                                </Col>
                                <Col>
                                    <h6>{t('analyser.leftSegment.label.toDate', 'To')}</h6>
                                    <Input className="analyser-dateInput" type="date" name="toDate" id="toDate" value={allTime || !toDate ? '' : moment(toDate).local().format('YYYY-MM-DD')} onChange={(e) => setToDate(moment(e.target.value))} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className={"pt-2 pl-2"}>{t('analyser.leftSegment.label.allTime', 'All time')}</Label>
                                    <>&nbsp;</>
                                    <input type="checkbox" name="allSelected" id="allSelected" checked={allTime} onChange={(e) => { setAllTime(!allTime); setDates(); }} />
                                </Col>
                            </Row>
                        </FormGroup>
                        {/*Trainer/Provider*/}
                        <Row>
                            <Col xs={12} lg={6}>

                                <AnalyserCheckedListModal
                                    allSelected={allProviders}
                                    setAllSelected={setAllProviders}
                                    isSelected={providers}
                                    setSelected={setProviders}
                                    title={"Trainer/Provider"}
                                    tags={uniqueLearningUnitsByField('provider') ?? []}
                                />
                            </Col>

                            <Col xs={12} lg={6}>
                                {/*Course Name*/}
                                <AnalyserCheckedListModal
                                    allSelected={allCourseNames}
                                    setAllSelected={setAllCourseNames}
                                    isSelected={courseNames}
                                    setSelected={setCourseName}
                                    title={"Course Name"}
                                    tags={uniqueLearningUnitsByField('name') ?? []}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                {/*Course Type*/}
                                <AnalyserCheckedListModal
                                    allSelected={allCourseTypes}
                                    setAllSelected={setAllCourseTypes}
                                    isSelected={courseTypes}
                                    setSelected={setCourseTypes}
                                    title={"Course Type"}
                                    tags={uniqueLearningUnitsByField('learningUnitType') ?? []}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                {/*Learner/Department*/}
                                <Collapse isOpen={!!subscription || !!profiles}>
                                    <AnalyserCheckedListModal
                                        allSelected={allLearnersDepartments}
                                        setAllSelected={setAllLearnersDepartments}
                                        isSelected={learnersDepartments}
                                        setSelected={setLearnersDepartments}
                                        title={"Learner / Department"}
                                        tags={learnersDepartmentsTags ?? []}
                                        groupTags={subscriptionDepartments?.map((department) => { return { id: department.id, name: department.name } as TagLikeItem }) ?? []}
                                        groupTagSelectClick={departmentSelectClick}
                                        getParentId={getParentDepartment}
                                    />
                                </Collapse>
                            </Col>

                        </Row>
                    </div>

                    <div className="analyser-subSegment">
                        <h4 className="analyser-heading">
                            {t('analyser.leftSegment.heading.impacts', 'Select IMPACTS')}
                        </h4>
                        <Row>
                            <Col xs={12} lg={6}>
                                <AnalyserCheckedListModal
                                    allSelected={allSectionNames}
                                    setAllSelected={setAllSectionNames}
                                    isSelected={sectionNames}
                                    setSelected={setSectionNames}
                                    title={"Section Name"}
                                    tags={uniqueQuestionnaireSectionNameTags ?? []}
                                    setSelectedNames={setSelectedSectionNames}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <AnalyserCheckedListModal
                                    allSelected={allCategories}
                                    setAllSelected={setAllCategories}
                                    isSelected={categories}
                                    setSelected={setCategories}
                                    title={"Category"}
                                    tags={analysisTypeTags ?? []}
                                    setSelectedNames={setSelectedCategories}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <AnalyserCheckedListModal
                                    allSelected={allImpacts}
                                    setAllSelected={setAllImpacts}
                                    isSelected={impacts}
                                    setSelected={setImpacts}
                                    title={"Impacts"}
                                    tags={questionTypeTags}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <AnalyserCheckedListModal
                                    allSelected={allMeasures}
                                    setAllSelected={setAllMeasures}
                                    isSelected={measures}
                                    setSelected={setMeasures}
                                    title={"Measures"}
                                    tags={measureQuestionResponseTags}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="analyser-subSegment" style={{ borderBottom: '0px' }}>
                        <h4 className="analyser-heading">
                            {t('analyser.leftSegment.heading.resources', 'Show Results By')}
                        </h4>
                        <Label>{t('analyser.leftSegment.label.showBy', 'Please tick from the options below')}</Label>
                        <Row>
                            <Col xs="auto">
                                <h6 className={"results-by-label"}>{t('analyser.leftSegment.label.category', 'Category')}</h6>
                            </Col>
                            <Col xs="auto">
                                <input type="checkbox" name="category" id="category" checked={showBy('category')} onChange={(e) => toggleShowBy('category')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto">
                                <h6 className={"results-by-label"}>{t('analyser.leftSegment.label.section', 'Section')}</h6>
                            </Col>
                            <Col xs="auto">
                                <input type="checkbox" name="category" id="category" checked={showBy('section')} onChange={(e) => toggleShowBy('section')} />
                            </Col>
                        </Row>
                    </div>
                    <div className="text-center">
                        <Button color={"primary"} className="analyser-submitButton" onClick={e => { interrogateData(); setShowRightSegment(true); }} disabled={loading}>
                            {t('analyser.leftSegment.button.submit', loading ? 'Loading...' : 'Submit')}
                        </Button>
                    </div>
                </Col>

                {/*Right segment*/}
                <ConditionalFragment showIf={showRightSegment}>
                    <Col xs={12} sm={6} xl={4} className="analyser-segment analyser-segment-right">
                        {/*Results*/}
                        <div className="analyser-subSegment">
                            <Row>
                                <Col>
                                    <h4 className="analyser-heading">
                                        {t('analyser.rightSegment.heading.results', 'Results')}
                                    </h4>
                                </Col>
                                <Col xs={"auto"}>
                                    <ButtonGroup>
                                        <PdfPrintButtons onClick={pdfPrintButtonOnClick} />
                                        <Button id="categoryGraph" className="analyser-imageButton d-flex justify-content-center" onClick={e => setCategoryGraphMode(!categoryGraphMode)}>
                                            <img className="analyser-graphImage" alt=''></img>
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={showResults}>
                                <Container className="analyser-resultsContainer">
                                    <Row>
                                        <Col>
                                        </Col>
                                        <Col xs="auto">
                                            <Button className="analyser-imageButton-closeResults" onClick={() => setShowResults(!showResults)}>
                                                {t('analyser.rightSegment.button.close', 'X')}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/*Total Learners*/}
                                    <Row>
                                        <Col xs={6}>
                                            <h5>{t('analyser.rightSegment.label.totalLearners', 'Total Learners')}</h5>
                                        </Col>
                                        <Col>
                                            <Input type="text" value={!!analysisResults?.learnerResults ? (analysisResults as AnalyserInterrogationResult)?.learnerResults.totalLearners.toLocaleString() : 0} readOnly style={{ backgroundColor: 'white', maxWidth: !!analysisResults?.resultsPieChartResults?.completedProfiles?.length ? '90%' : '40%' }}></Input>
                                        </Col>
                                    </Row>
                                    {/*Total Cost*/}
                                    <Row>
                                        <Col xs={6}>
                                            <h5>{t('analyser.rightSegment.label.totalCost', 'Total Cost')}</h5>
                                        </Col>
                                        <Col>
                                            <Input type="text" value={'£' + (!!analysisResults?.learnerResults ? analysisResults?.learnerResults.totalCost.toLocaleString() : 0)} readOnly style={{ backgroundColor: 'white', maxWidth: !!analysisResults?.resultsPieChartResults?.completedProfiles?.length ? '90%' : '40%' }}></Input>
                                        </Col>
                                    </Row>
                                    {/*Cost per Learner*/}
                                    <Row>
                                        <Col xs={6}>
                                            <h5>{t('analyser.rightSegment.label.costPerLearner', 'Cost per Learner')}</h5>
                                        </Col>
                                        <Col>
                                            <Input type="text" value={'£' + (!!analysisResults?.learnerResults ? analysisResults?.learnerResults.costPerLearner.toLocaleString() : 0)} readOnly style={{ backgroundColor: 'white', maxWidth: !!analysisResults?.resultsPieChartResults?.completedProfiles?.length ? '90%' : '40%' }}></Input>
                                        </Col>
                                    </Row>
                                </Container>
                            </Collapse>
                            <ConditionalFragment showIf={!showResults}>
                                <Col className={"text-center pb-1"}>
                                    <Button color="primary" onClick={() => setShowResults(true)}>
                                        <> {t('questionnaireOverview.show-graph', 'Show Results')}</>
                                    </Button>
                                </Col>
                            </ConditionalFragment>
                        </div>
                        {/*Category*/}
                        <Collapse isOpen={showBy('category')}>
                            <div className="analyser-subSegment">
                                <Row>
                                    <Col>
                                        <h4 className="analyser-heading">
                                            {t('analyser.rightSegment.heading.category', 'Category')}
                                        </h4>
                                    </Col>
                                    <Col xs={"auto"}>
                                        <ButtonGroup>
                                            <PdfPrintButtons onClick={pdfPrintButtonOnClick} />
                                            <Button id="categoryGraph" className="analyser-imageButton d-flex justify-content-center" onClick={e => setCategoryGraphMode(!categoryGraphMode)}>
                                                <img className="analyser-graphImage" alt=''></img>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <DndContext onDragEnd={reorderCategories}>
                                    <ul>
                                        <SortableContext items={selectedCategories?.filter(cat => !!analysisResults?.categoryResults?.find(it => it.analysisType.id === cat)) ?? []}>
                                            {
                                                selectedCategories?.map((cat, index) => {
                                                    const resultItem = analysisResults?.categoryResults?.find(it => it.analysisType.id === cat);
                                                    return !!resultItem ? (<AnalyserDragComponent key={resultItem !== undefined ? resultItem.analysisType.id : ""}
                                                        id={cat}
                                                        name={resultItem.analysisType.name}
                                                        figure={resultItem !== undefined ? resultItem.data.result : 0}
                                                        change={resultItem !== undefined ? resultItem.data.arrow : undefined}
                                                        iconSuffix={getIconCssSuffixFromCategoryName((resultItem !== undefined ? resultItem.analysisType.name : "").toLowerCase().replaceAll(' ', ''))}
                                                        exclude={excludeCategory}
                                                        graphMode={categoryGraphMode}
                                                        includeInGraph={graphCategories(resultItem !== undefined ? resultItem.analysisType.id : "")}
                                                        toggleIncludeInGraph={setGraphCategories}
                                                    />
                                                    ) : null
                                                })
                                            }
                                        </SortableContext>
                                    </ul>
                                </DndContext>
                                <ConditionalFragment showIf={categoryGraphMode && !!categoryChartData[0].data.length}>
                                    <div className={"text-center"}>
                                        <Button color={"primary"} className="analyser-submitButton" onClick={e => { toggleGraphModalOpen(true); setShowCatGraph(true); setIncludeGraph(true); setCategoryGraphMode(!categoryGraphMode) }} disabled={loading || !profiles}>
                                            {t('analyser.leftSegment.button.generateCatGraph', isQuestionsLoading ? 'Generating...' : 'Create Graph')}
                                        </Button>
                                    </div>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!loading && noResultCategories.length > 0}>
                                    <h5>{t('analyser.rightSegment.noCategoryResults', 'No results for selected categories:')}</h5>
                                    <ul>
                                        {
                                            noResultCategories.map((cat, index) => {
                                                let analysisType = analysisTypeTags.find(it => it.id === cat);
                                                return (<li key={index}>{analysisType?.name}</li>)
                                            })
                                        }
                                    </ul>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={graphModalOpen}>
                                    <AnalyserSelectGraphTypeModal
                                        isOpen={graphModalOpen}
                                        toggle={toggleGraphModalOpen}
                                        setGraphType={setCategoryGraphFormat}
                                    />
                                </ConditionalFragment>
                            </div>
                        </Collapse>
                        {/*Section*/}
                        <Collapse isOpen={showBy('section')}>
                            <div className="analyser-subSegment">
                                <Row>
                                    <Col>
                                        <h4 className="analyser-heading">
                                            {t('analyser.rightSegment.heading.sections', 'Section')}
                                        </h4>
                                    </Col>
                                    <Col xs={"auto"}>
                                        <ButtonGroup>
                                            <PdfPrintButtons onClick={pdfPrintButtonOnClick} />
                                            <Button id="categoryGraph" className="analyser-imageButton d-flex justify-content-center" onClick={e => setCategoryGraphMode(!categoryGraphMode)}>
                                                <img className="analyser-graphImage" alt=''></img>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <DndContext onDragEnd={reorderSectionNames}>
                                    <ul>
                                        <SortableContext items={selectedSectionNames.filter(item => getRespondants(item).length > 0) ?? []}>
                                            {
                                                selectedSectionNames.filter(item => getRespondants(item).length > 0)?.map((sectionName) => {
                                                    const allResultItems = analysisResults?.sectionResults?.filter(it => it.sectionName === sectionName)
                                                        .sort((a, b) => a.questionnaireName.localeCompare(b.questionnaireName)) ?? [];

                                                    const allUniqueResponseIds: Array<string> = [];
                                                    let allFigures = 0;

                                                    for (const resultItem of allResultItems) {
                                                        const responses = resultItem?.sectionQuestionData.flatMap(d => d.responses).filter(d => learnersDepartments(d.userId)) ?? [];
                                                        const uniqueRespondantIds = [...new Set(responses.map(r => r.userId))];
                                                        if (uniqueRespondantIds.length) {
                                                            allUniqueResponseIds.push(...uniqueRespondantIds);
                                                        }
                                                        allFigures += resultItem.sectionQuestionData.length;
                                                    }

                                                    const respondants = profiles?.filter(p => allUniqueResponseIds.find(id => id === p.userId));
                                                    return (

                                                        <AnalyserDragComponent key={uniqueQuestionnaireSectionNameTags?.find(a => a.name === sectionName)?.id}
                                                            id={uniqueQuestionnaireSectionNameTags?.find(a => a.name === sectionName)?.id ?? sectionName}
                                                            name={sectionName}
                                                            figure={allFigures}
                                                            analyserReportSections={allResultItems ?? undefined}
                                                            exclude={excludeSection}
                                                            respondants={respondants}
                                                            toggleIncludeInGraph={() => ''}
                                                        />
                                                    )
                                                })
                                            }
                                            <ConditionalFragment showIf={!loading && !!noResultSections && noResultSections.length > 0}>
                                                <h5>{t('analyser.rightSegment.noSectionResults', 'No results for selected sections:')}</h5>
                                                <ul>
                                                    {
                                                        noResultSections.map((section, index) => {
                                                            return (<li key={index}>{section}</li>)
                                                        })
                                                    }
                                                </ul>
                                            </ConditionalFragment>

                                        </SortableContext>
                                    </ul>
                                </DndContext>
                            </div>
                        </Collapse>
                        {/*Graph Results*/}
                        <div className="analyser-subSegment graph-results">
                            <Row>
                                <Col>
                                    <h4 className="analyser-heading">
                                        {t('analyser.rightSegment.heading.graphResults', 'Graph Results')}
                                    </h4>
                                </Col>
                                <Col xs={"auto"}>
                                    <ButtonGroup>
                                        <PdfPrintButtons onClick={pdfPrintButtonOnClick} />
                                        <Button id="categoryGraph" className="analyser-imageButton d-flex justify-content-center" onClick={e => setCategoryGraphMode(!categoryGraphMode)}>
                                            <img className="analyser-graphImage" alt=''></img>
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <ConditionalFragment showIf={showCatGraph}>
                                <Row>
                                    <Col></Col>
                                    <Col xs={"auto"}>
                                        <Button className="analyser-imageButton-closeResults" onClick={() => { setIncludeGraph(false); setShowCatGraph(false); }}>
                                            {t('analyser.rightSegment.button.close', 'X')}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Chart key={catChartKey} options={catOptions} series={catSeries} type={'bar'}></Chart>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                        </div>
                    </Col>
                </ConditionalFragment>
            </Row>

            {analyserNode}

        </Background>
    );
};

function useCompletionChartData(totalNotCompleted: number, totalCompleted: number): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback(() => {
        const ret = [
            {
                text: t('analyser.resultsChart.completed', 'Completed'),
                value: totalCompleted
            },
            {
                text: t('analyser.resultsChart.notCompleted', 'Not Completed'),
                value: totalNotCompleted
            },
        ]

        return {
            name: "",
            data: ret
        } as ChartDataSeries;
    }, [totalCompleted, totalNotCompleted, t]);

    const ret = useMemo(() => {
        return [
            generateSeries(),
        ];
    }, [generateSeries]);

    return ret;
}

function useCategoryChartData(data: { analysisType: AnalysisType, data: { result: number, arrow?: 'up' | 'down' } }[]): Array<ChartDataSeries> {

    const { t } = useTranslation();

    const generateSeries = useCallback(() => {
        const ret = data.map((item) => {
            const arrow = item.data.arrow;
            return {
                text: t('analyser.selectionChart.key', item.analysisType.name),
                value: arrow === 'down' ? -item.data.result : item.data.result,
            }
        })

        return {
            name: "",
            data: ret,
        } as ChartDataSeries;

    }, [data, t])

    const ret = useMemo(() => {
        return [generateSeries()];
    }, [generateSeries])

    return ret;
}

function checkIfAllSelected(toggleStateArray: GetToggleStateArrayValue<string | null>, availableItems: TagLikeItem[]) : boolean {
    // Make sure allLearnersDepartments gets set if all are selected individually
    let selected = 0;
    availableItems.forEach((item) => {
        if (toggleStateArray(item.id)) {
            selected++;
        }
    })

    return selected === availableItems.length;

}


export interface AnalyserReportSection {
    id: string,
    sectionName: string;
    questionnaireName: string;
    sectionQuestionData: Array<SectionQuestionData>;
}

export interface SectionQuestionData {
    question: Question;
    responses: Array<QuestionResponse>;
    include: boolean;
}