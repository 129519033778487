import { Text, View } from "@react-pdf/renderer";
import { Question } from "../../../../api/main/models/Question";
import { ComparisonScaleResponsesStyles } from "./comparisonScaleResponsesStyles";

//* Component for comparison scale responses for pdf
interface ComparisonScaleResponsesProps {
    companyColor: string,
    question: Question,
    questionIndex: number,
    responsePairsCount: number,
    beforeTotal: number,
    afterTotal: number,
}

export const ComparisonScaleResponses = (props: ComparisonScaleResponsesProps) => {
    const {
        companyColor, question, questionIndex, responsePairsCount,beforeTotal, afterTotal,
    } = props;

    let averageBefore = beforeTotal / (responsePairsCount ?? 1);
    let averageAfter = afterTotal / (responsePairsCount ?? 1);

    /* render the figure in the correct format */
    let averageBeforeText = question.isPercentage ? `${averageBefore.toFixed(0)}%` : `${averageBefore.toFixed(0)}/${question.scaleMax}`;
    let averageAfterText = question.isPercentage ? `${averageAfter.toFixed(0)}%` : `${averageAfter.toFixed(0)}/${question.scaleMax}`;

    // Adjust the length of the progress bars if we are not dealing with a percentage response
    averageBefore = question.isPercentage ? averageBefore : (beforeTotal / (responsePairsCount ?? 1)) * (100 / question.scaleMax);
    averageAfter = question.isPercentage ? averageAfter : (afterTotal / (responsePairsCount ?? 1)) * (100 / question.scaleMax);

    return (
        <View wrap={false} key={questionIndex}>
            {/*<View style={ComparisonScaleResponsesStyles.subHeadingContainer}>*/}
            {/*    <Text style={ComparisonScaleResponsesStyles.subHeadingBold} >{question.questionText.replace('&amp;', '&')}</Text>*/}
            {/*</View>*/}
            <View style={ComparisonScaleResponsesStyles.progressContainer}>
                <View style={ComparisonScaleResponsesStyles.progress}>
                    <Text style={ComparisonScaleResponsesStyles.progressText}>{'Before:'}</Text>
                    <View style={ComparisonScaleResponsesStyles.progressBarContainer}>
                        <View style={[ComparisonScaleResponsesStyles.progressBar, { width: !!averageBefore ? `${averageBefore.toFixed(0)}%` : '0%' }]}></View>
                    </View>
                    <View style={ComparisonScaleResponsesStyles.progressTextContainer}>
                        <Text style={[ComparisonScaleResponsesStyles.progressText, ComparisonScaleResponsesStyles.progressTextBold]}>{averageBeforeText}</Text>
                    </View>
                </View>
                <View style={ComparisonScaleResponsesStyles.progress}>
                    <Text style={ComparisonScaleResponsesStyles.progressText}>{'After:'}</Text>
                    <View style={ComparisonScaleResponsesStyles.progressBarContainer}>
                        <View style={[ComparisonScaleResponsesStyles.progressBar, { backgroundColor: companyColor, width: !!averageAfter ? `${averageAfter.toFixed(0)}%` : '0%' }]}></View>
                    </View>
                    <View style={ComparisonScaleResponsesStyles.progressTextContainer}>
                        <Text style={[ComparisonScaleResponsesStyles.progressText, ComparisonScaleResponsesStyles.progressTextBold]}>{averageAfterText}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
};
