import { Document, Page, View, Image, Text} from '@react-pdf/renderer';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AnalyserInterrogationResult } from '../../dashboard/analyser/AnalyserDataInterrogation';
import { AnalyserReportFooter } from './analyserReportComponents/AnalyserReportFooter';
import { AnalyserReportPage } from './analyserReportComponents/AnalyserReportPage';
import { AnalyserReportPageStyles } from './analyserReportComponents/analyserReportPageStyles';
import { DetailsPage } from './analyserReportComponents/DetailsPage';
import { SectionQuestionsAnswersPage } from './analyserReportComponents/SectionQuestionsAnswersPage';
import { AnalyserReportIndividualStyles } from './AnalyserReportIndividualPDFStyles';


interface AnalyserReportIndividualPDFProps {
    companyColor: string,
    impactsLogoUrl: string,
    splashPageImage: string,
    reportTitle: string,
    questionnaireName?: string,
    reporterName: string,
    analysisResults: AnalyserInterrogationResult | undefined,
    learner: { id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string },
    analysisTypeIconUrls?: { analysisTypeName: string, iconUrl: string }[] | undefined,
    isCampaignReport: boolean,
    currencySymbol: string,
}

/* PDF report for an individual */
export const AnalyserReportIndividualPdf = (props: AnalyserReportIndividualPDFProps) => {

    const { companyColor, impactsLogoUrl, splashPageImage,
        reportTitle,
        questionnaireName = '',
        reporterName, analysisResults,
        learner,
        analysisTypeIconUrls = [],
        isCampaignReport,
        currencySymbol,
    } = props;

    const formattedPrintDate = moment(new Date()).format("Do MMMM YYYY");

    const performancePageSubtitle = 'What results did you get and how did you know you were successful?';

    const isPerformanceSection = analysisResults?.sectionResults.find(section => section.sectionName.toLocaleLowerCase() === 'performance');

    return (
        <Document>
            {/*Splash Page*/}
            <Page size="A4" style={AnalyserReportPageStyles.reportPage} wrap={false}>
                <View style={AnalyserReportIndividualStyles.body}>
                    <View>
                        <Image style={AnalyserReportIndividualStyles.impactsImageMainPage} source={impactsLogoUrl} />
                    </View>

                    <View>
                        <Text style={AnalyserReportIndividualStyles.mainHeaderBlack}>{reporterName}</Text>
                    </View>

                    <View style={[AnalyserReportIndividualStyles.blueBlock,
                    {
                        backgroundColor: companyColor,
                    }]}>
                        <View style={AnalyserReportIndividualStyles.blueBlockTextContainer}>
                            <Text style={[AnalyserReportIndividualStyles.blueBlockText, AnalyserReportIndividualStyles.blueBlockTextLarge]}>{reportTitle}</Text>
                        </View>
                        <View style={[AnalyserReportIndividualStyles.blueBlockTextContainer, { marginTop: "10mm" }]}>
                            <Text style={[AnalyserReportIndividualStyles.blueBlockText, AnalyserReportIndividualStyles.blueBlockTextLarge]}>Individual Report</Text>
                            <Text style={AnalyserReportIndividualStyles.blueBlockText}>{formattedPrintDate}</Text>
                        </View>
                    </View>

                    <View>
                        <Image style={AnalyserReportIndividualStyles.splashPageImage} source={splashPageImage} />
                    </View>

                </View>
                <AnalyserReportFooter impactsLogoUrl={impactsLogoUrl} isSplashPage={true} />
            </Page>

            {/*Report Page - Details*/}
            <AnalyserReportPage
                companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Details'} reportTitle={reportTitle}
                children={
                    <DetailsPage
                        reportTitle={reportTitle}
                        questionnaireName={questionnaireName}
                        numberOfLearners={analysisResults?.learnerResults.totalLearners ?? undefined}
                        totalCost={analysisResults?.learnerResults.totalCost ?? undefined}
                        costPerLearner={analysisResults?.learnerResults.costPerLearner ?? undefined}
                        providerNames={[]}
                        courseNames={[]}
                        courseTypes={[]}
                        sectionNames={[]}
                        categoryNames={[]}
                        departmentNames={[]}
                        learners={[learner]}
                        isAllLearners={false}
                        isCampaignReport={isCampaignReport}
                        currencySymbol={currencySymbol}
                    />} />

            {/* Special page if there is no performance section - If there is a Performance section just leave that to happen in order below */}
            <ConditionalFragment showIf={!isPerformanceSection}>
                <AnalyserReportPage
                    companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Performance'} reportTitle={reportTitle} subTitle={performancePageSubtitle}
                    children={
                        <SectionQuestionsAnswersPage
                            companyColor={companyColor}
                            learners={[learner]}
                            questions={[]}
                            responses={[]}
                            analysisTypeIconUrls={analysisTypeIconUrls}
                            categoryResults={analysisResults?.categoryResults}
                            currencySymbol={currencySymbol}
                        />} />
            </ConditionalFragment>
            {/* Report Pages - for every section*/}
            {
                analysisResults?.sectionResults.map((section, sectionIndex) => {
                    return (
                        <AnalyserReportPage
                            key={sectionIndex}
                            companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={section.sectionName} reportTitle={reportTitle}
                            children={
                                <SectionQuestionsAnswersPage
                                    companyColor={companyColor}
                                    learners={[learner]}
                                    questions={section.sectionQuestionData.map(item => item.question)}
                                    responses={section.sectionQuestionData.flatMap(item => item.responses).filter(item => item.userId === learner.id)}
                                    analysisTypeIconUrls={section.sectionName.toLocaleLowerCase() === 'performance' ? analysisTypeIconUrls : undefined}
                                    categoryResults={section.sectionName.toLocaleLowerCase() === 'performance' ? analysisResults?.categoryResults : undefined}
                                    currencySymbol={currencySymbol}
                                />} />
                    )
                })

            }
        </Document>
    );
};