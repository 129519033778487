import { Text, View } from "@react-pdf/renderer";
import { ConditionalFragment } from "react-conditionalfragment";
import { SectionPageStyles } from "./sectionPageStyles";
import { AnalyserResponse } from "./AnalyserResponses";
import { AnalyserReportSection } from "../../../dashboard/analyser/Analyser";
import { useTranslation } from "react-i18next";

//* Common reporting page for analyser reports - Performance Page
interface SectionPageProps {
    companyColor: string,
    learners: Array<{ id: string, firstName: string, lastName: string }>,
    reportSection: AnalyserReportSection,
    currencySymbol: string,
}

export const SectionPage = (props: SectionPageProps) => {
    const {
        companyColor, learners, reportSection, currencySymbol,
    } = props;
    const { t } = useTranslation();

    const sortedLearners = learners.sort((a, b) => {
        if (a.firstName === b.firstName) {
            if (a.lastName === b.lastName) {
                return 0;
            } else if (a.lastName > b.lastName) {
                return 1;
            } else {
                return -1;
            }
        } else if (a.firstName > b.firstName) {
            return 1;
        } else {
            return -1;
        }
    });


    return (
        <>
            {
                reportSection.sectionQuestionData.map((sectionQuestion, questionIndex) => {
                    const question = sectionQuestion.question;
                    const responses = sectionQuestion.responses;
                    const uniqueResponseLearners = sortedLearners.filter(learner => responses.some(response => response.userId === learner.id));
                    return (
                        <View key={sectionQuestion.question.id}>
                            <View style={SectionPageStyles.subHeadingContainer}>
                                <Text style={SectionPageStyles.subHeadingBold} >{question.questionText}</Text>
                            </View>

                            <View>
                                {
                                    uniqueResponseLearners.map((learner, index) => {
                                        // Responses for this question from this learner
                                        const learnerQuestionResponses = responses.filter(response => response.userId === learner.id);

                                        return (
                                            <View key={index} style={SectionPageStyles.learnerResponsesContainer}>
                                                <ConditionalFragment showIf={learners.length > 1}>
                                                    <Text style={SectionPageStyles.learnerName}>{`${learner?.firstName ?? ''} ${learner?.lastName ?? ''} -`}</Text>
                                                </ConditionalFragment>
                                                <AnalyserResponse
                                                    companyColor={companyColor}
                                                    question={question}
                                                    responses={learnerQuestionResponses}
                                                    preText={!!question.isIncrease ? t('sectionPage.responseUp', 'Up') : question.isIncrease === false ? t('sectionPage.responseDown', 'Down'): ''}
                                                    currencySymbol={currencySymbol}
                                                    questionIndex={questionIndex}
                                                />
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    )
                })
            }
        </>
    );
};