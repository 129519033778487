import { Text, View } from "@react-pdf/renderer";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionResponseType } from "../../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../../api/main/models/Question";
import { QuestionResponse } from "../../../../api/main/models/QuestionResponse";
import { getMultipleChoiceResponseText } from "../../../questions/utilities/getMultipleChoiceResponseText";
import { AnalyserResponsesStyles } from "./analyserResponsesStyles";
import { ComparisonScaleResponses } from "./ComparisonScaleResponses";

//* component for displaying all responses to a question for a user on an analyser report
interface AnalyserResponseProps {
    companyColor: string;
    question: Question,
    responses: QuestionResponse[],
    preText: string,
    currencySymbol: string,
    questionIndex: number,
}

export const AnalyserResponse = (props: AnalyserResponseProps) => {
    const {
        companyColor, question, responses, preText, currencySymbol, questionIndex,
    } = props;

    const sortedResponses = useMemo(() => {
        if (!responses) {
            return [];
        }

        return responses.sort((a, b) => a.displayOrder - b.displayOrder);
    }, [responses]);

    // present a formatted response depending on response type 
    const displayResponse = useCallback((response: QuestionResponse) => {
        var ret = '';

        // have we actually had a response from the user?
        if (!response.isUserInput) {
            return ret;
        }

        // depending on the type of response - get the correct field for the display
        if (question.responseType === QuestionResponseType.TextBoxes) {
            ret = response.textInput;
        } else if (question.responseType === QuestionResponseType.YesNo) {
            ret = response.yesNoInput ? 'Yes' : 'No';
        } else if (question.responseType === QuestionResponseType.MultipleChoice || question.responseType === QuestionResponseType.PickOne) {
            // Amount input will contain the number of the response chosen starting at 1 so we need to subtract 1 to get the correct index
            ret = getMultipleChoiceResponseText(question, response.amountInput - 1) ?? '';
        } else {
            // default as all other response types are amount input
            ret = response.amountInput.toLocaleString();
        }

        // handle formatting for currency and percentage
        if (question.isPercentage || question.responseType === QuestionResponseType.Percentage) {
            ret += '%';
        }
        if (question.responseType === QuestionResponseType.CurrencyAmount) {
            ret = (currencySymbol ?? '£') + ret;
        }

        // Handle a scale response (comparison scale will be handled separately in the ui below)
        if (question.responseType === QuestionResponseType.Scale) {
            ret = `${ret}/${question.scaleMax}`;
        }

        return ret;
    }, [currencySymbol, question,]);

    const totals = useMemo(() => {
        let ret = { beforeTotal: 0, afterTotal: 0 };

        if (responses.length < 2) {
            return ret;
        }

        ret.beforeTotal = responses[0].amountInput;
        ret.afterTotal += responses[1].amountInput;

        return ret;

    }, [responses,]);


    /* render Ui */

    return (
        <>
            <ConditionalFragment showIf={question.responseType === QuestionResponseType.ComparisonScale && sortedResponses.length > 1}>
                <ComparisonScaleResponses
                    companyColor={companyColor}
                    question={question}
                    questionIndex={questionIndex}
                    responsePairsCount={1}
                    beforeTotal={totals.beforeTotal}
                    afterTotal={totals.afterTotal}
                />
            </ConditionalFragment>
            <ConditionalFragment showIf={question.responseType !== QuestionResponseType.ComparisonScale}>
                {
                    sortedResponses.map((response, responseIndex) => (
                        <View style={AnalyserResponsesStyles.responseContainer} key={responseIndex}>
                            <Text style={[AnalyserResponsesStyles.responseText, AnalyserResponsesStyles.responseTextBold, AnalyserResponsesStyles.pretext]}>{preText ?? ''}</Text>
                            <Text style={[AnalyserResponsesStyles.responseText, AnalyserResponsesStyles.responseTextBold, AnalyserResponsesStyles.pretext]}>{sortedResponses.length > 1 ? ` ${responseIndex + 1}: ` : ''}</Text>
                            <Text style={[AnalyserResponsesStyles.responseText, AnalyserResponsesStyles.responseWithPretext]}>{displayResponse(response)}</Text>
                        </View>
                    ))
                }
            </ConditionalFragment>
        </>
    );
};