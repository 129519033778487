import { Document, Page, View, Image, Text} from '@react-pdf/renderer';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AnalyserReportSection } from '../../dashboard/analyser/Analyser';
import { AnalyserInterrogationResult } from '../../dashboard/analyser/AnalyserDataInterrogation';
import { AnalyserReportFooter } from './analyserReportComponents/AnalyserReportFooter';
import { AnalyserReportPage } from './analyserReportComponents/AnalyserReportPage';
import { AnalyserReportPageStyles } from './analyserReportComponents/analyserReportPageStyles';
import { DetailsPage } from './analyserReportComponents/DetailsPage';
import { PerformanceGraphPage } from './analyserReportComponents/PerformanceGraphPage';
import { PerformancePage } from './analyserReportComponents/PerformancePage';
import { SectionsPage, } from './analyserReportComponents/SectionsPage';
import { AnalyserReportStyles } from './AnalyserReportPDFStyles';


interface AnalyserReportPDFProps {
    companyColor: string,
    currencySymbol: string,
    impactsLogoUrl: string,
    splashPageImage: string,
    reportTitle: string,
    questionnaireName?: string,
    reporterName: string,
    analysisResults: AnalyserInterrogationResult | undefined,
    providerNames: string[] | undefined,
    courseNames: string[] | undefined,
    courseTypes: string[] | undefined,
    sectionNames: string[] | undefined,
    categoryNames: string[] | undefined,
    departmentNames: string[] | undefined,
    analysisTypeIconUrls?: { analysisTypeName: string, iconUrl: string }[] | undefined,
    chartImages?: string[],
    isAllLearners: boolean,
    isCampaignReport: boolean,
    reportSections: AnalyserReportSection[],
    learners: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }>,
}

/* PDF report for the analyser */
export const AnalyserReportPdf = (props: AnalyserReportPDFProps) => {

    const { companyColor,
        currencySymbol,
        impactsLogoUrl, splashPageImage,
        reportTitle,
        questionnaireName = '',
        reporterName, analysisResults,
        providerNames = [],
        courseNames = [],
        courseTypes = [],
        sectionNames = [],
        categoryNames = [],
        departmentNames = [],
        analysisTypeIconUrls = [],
        chartImages = [],
        isAllLearners,
        isCampaignReport,
        reportSections,
        learners,
    } = props;

    const formattedPrintDate = moment(new Date()).format("Do MMMM YYYY");

    const performancePageSubtitle = 'What results did you get and how did you know you were successful?';

    let uniqueSectionNames: Array<string> = [];
    for (const reportSection of reportSections) {
        if (!uniqueSectionNames.includes(reportSection.sectionName)) {
            uniqueSectionNames.push(reportSection.sectionName);
        }
        uniqueSectionNames = uniqueSectionNames.sort((a, b) => a.localeCompare(b));
    }


    return (
        <Document>
            {/*Splash Page*/}
            <Page size="A4" style={AnalyserReportPageStyles.reportPage} wrap={false}>
                <View style={AnalyserReportStyles.body}>
                    <View>
                        <Image style={AnalyserReportStyles.impactsImageMainPage} source={impactsLogoUrl} />
                    </View>

                    <View>
                        <Text style={AnalyserReportStyles.mainHeaderBlack}>{reporterName}</Text>
                    </View>

                    <View style={[AnalyserReportStyles.blueBlock,
                    {
                        backgroundColor: companyColor,
                    }]}>
                        <View style={AnalyserReportStyles.blueBlockTextContainer}>
                            <Text style={[AnalyserReportStyles.blueBlockText, AnalyserReportStyles.blueBlockTextLarge]}>{reportTitle}</Text>
                        </View>
                        <View style={[AnalyserReportStyles.blueBlockTextContainer, { marginTop: "10mm" }]}>
                            <Text style={[AnalyserReportStyles.blueBlockText, AnalyserReportStyles.blueBlockTextLarge]}>Analyser Report</Text>
                            <Text style={AnalyserReportStyles.blueBlockText}>{formattedPrintDate}</Text>
                        </View>
                    </View>

                    <View>
                        <Image style={AnalyserReportStyles.splashPageImage} source={splashPageImage} />
                    </View>

                </View>
                <AnalyserReportFooter impactsLogoUrl={impactsLogoUrl} isSplashPage={true} />
            </Page>

            {/*Report Page - Details*/}
            <AnalyserReportPage
                companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Details'} reportTitle={reportTitle}
                children={
                    <DetailsPage
                        reportTitle={reportTitle}
                        questionnaireName={questionnaireName}
                        numberOfLearners={analysisResults?.learnerResults.totalLearners ?? undefined}
                        totalCost={analysisResults?.learnerResults.totalCost ?? undefined}
                        costPerLearner={analysisResults?.learnerResults.costPerLearner ?? undefined}
                        providerNames={providerNames}
                        courseNames={courseNames}
                        courseTypes={courseTypes}
                        sectionNames={sectionNames}
                        categoryNames={categoryNames}
                        departmentNames={departmentNames}
                        learners={learners}
                        isAllLearners={isAllLearners}
                        isCampaignReport={isCampaignReport}
                        currencySymbol={currencySymbol}
                    />} />

            {/*Report Page - Performance */}
            <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Overall Performance'} reportTitle={reportTitle} subTitle={performancePageSubtitle}
                children={
                    <PerformancePage
                        categoryResults={analysisResults?.categoryResults ?? []}
                        analysisTypeIconUrls={analysisTypeIconUrls}
                    />
                } />

            {/*Report Page - Performance Graph */}
            <ConditionalFragment showIf={chartImages.length > 0}>
                <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Overall Performance'} reportTitle={reportTitle}
                    children={
                        <PerformanceGraphPage
                            chartImages={chartImages}
                        />
                    } />
            </ConditionalFragment>

            {/* Add a page or pages for each section */}

            {
                uniqueSectionNames.map((sectionName, sectionNameIndex) => {
                    const allItems = reportSections.filter(item => item.sectionName === sectionName);
                    return (
                        <AnalyserReportPage key={sectionNameIndex} companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={sectionName} reportTitle={reportTitle}
                            children={
                                <SectionsPage
                                    companyColor={companyColor}
                                    learners={learners}
                                    reportSections={allItems}
                                    currencySymbol={currencySymbol}
                                />
                            } />
                    )
                })
            }

        </Document>
    );
};