import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const SectionsPageStyles = StyleSheet.create({
    questinnaireHeading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
});

